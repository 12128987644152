<template>
    <WindowLayout :title="title">
        <div class="row">
            <div class="col-sm-12">
                <div class="darmin card">
                    <div class="card-header pb-0 d-flex align-items-center">
                        <h5>{{ headerTitle }}</h5>
                    </div>
                    <div class="card-body theme-form">
                        <div class="row">
                            <div class="col">
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Nombre</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.name"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Apellidos</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.surnames"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Email</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.email"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Sexo</label>
                                    <div class="col-sm-9">
                                        <select
                                            class="form-select btn-square digits"
                                            v-model="state.sex"
                                            :class="{ 'is-invalid': v.sex.$error }"
                                        >
                                            <option disable></option>
                                            <option value="H">Masculino</option>
                                            <option value="M">Femenino</option>
                                        </select>
                                        <div class="invalid-feedback" v-if="v.sex.$error">
                                            {{ v.sex.$errors[0].$message }}
                                        </div>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Especialidad</label>
                                    <div class="col-sm-9">
                                        <Autocomplete
                                            inputClass=""
                                            :debounce="1200"
                                            @input="getEspecialidades"
                                            :items="especialidades"
                                            :displayItem="
                                                (item) =>
                                                    typeof item === 'string' ? item : item.name
                                            "
                                            placeholder="Buscar una especialidad"
                                            @onSelect="onSelectSpecialty"
                                            ref="specialtyAutocomplete"
                                            :class="{ 'is-invalid': v.specialty.$error }"
                                        ></Autocomplete>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Info adicional</label>
                                    <div class="col-sm-9">
                                        <input
                                            class="form-control"
                                            type="text"
                                            v-model="state.linea2"
                                        />
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Temaplates</label>
                                    <div class="col-sm-9">
                                        <Treeselect
                                            v-if="isReady"
                                            v-model="state.templates"
                                            :options="templates"
                                            :multiple="true"
                                            value-consists-of="ALL_WITH_INDETERMINATE"
                                        ></Treeselect>
                                    </div>
                                </div>
                                <div class="mb-3 row">
                                    <label class="col-sm-3 col-form-label">Estado</label>
                                    <div class="col-sm-9">
                                        <SelectInputSimpleState v-model="state.status" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col">
                <div class="darmin card header-card">
                    <div class="row">
                        <div class="col-12 text-end">
                            <div
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('save')"
                                v-if="state.id"
                            >
                                <i class="far fa-save"></i>
                                Guardar
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new')"
                            >
                                <i class="far fa-save"></i>
                                Crear
                            </div>
                            <div
                                v-if="!state.id"
                                class="btn btn-primary ms-3 mb-1"
                                @click="handleAction('new_view')"
                            >
                                <i class="far fa-save"></i>
                                Crear y mostrar
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <pre>
                {{ state }}
            </pre>
        </div>
    </WindowLayout>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { reactive, onMounted, computed, ref } from 'vue';
import useVuelidate from '@vuelidate/core';
import { required, email } from '@vuelidate/validators';
import { useStore } from 'vuex';
// Components
import Treeselect from 'vue3-treeselect';
import { useSnackbar } from 'vue3-snackbar';
import WindowLayout from '@/layouts/Window.layout.vue';
// import Autocomplete from '@/components/form/Autocomplete.vue';
import Autocomplete from '@/components/form/Autocomplete.vue';
import SelectInputSimpleState from '@/components/form/SelectInputSimpleState.vue';

// Services
import useDoctor from './services/useDoctor';
import useExamenTemplate from '../ExamenTemplate/services/useExamenTemplate';
import useEspecialidades from '../Especialidades/services/useEspecialidades';

export default {
    name: 'DoctorForm',
    components: {
        WindowLayout,
        SelectInputSimpleState,
        Treeselect,
        Autocomplete,
    },
    props: {
        title: { type: String, default: 'Especialista' },
        headerTitle: { type: String, default: 'Ingresar Especialista' },
        item: Object,
    },
    setup(props) {
        const isReady = ref(false);
        const store = useStore();
        const snackbar = useSnackbar();
        const state = reactive({
            id: '',
            consecutive: '',
            name: '',
            surnames: '',
            email: '',
            sex: '',
            specialty: '',
            linea2: '',
            firma: '',
            templates: [],
            status: 1,
        });

        // eslint-disable-next-line object-curly-newline
        const { doctor, getDoctor, createDoctor, updateDoctor } = useDoctor();
        const { examenTemplates: templates, getExamenTemplates } = useExamenTemplate();
        const { especialidades, getEspecialidades } = useEspecialidades();

        /** ***** Autocompleters ***** */
        const specialtyAutocomplete = ref(); // autocomplete input
        const specialtySelected = ref(null);
        const onSelectSpecialty = (specialty) => {
            console.log('specialty', specialty);
            state.specialty = specialty.id;
            specialtyAutocomplete.value.setText(specialty.name);
            specialtySelected.value = specialty;
        };

        /** ***** Helpers ***** */
        const setState = () => {
            state.id = doctor.value.id;
            state.consecutive = doctor.value.consecutive;
            state.name = doctor.value.name;
            state.surnames = doctor.value.surnames;
            state.email = doctor.value.email;
            state.sex = doctor.value.sex;
            state.specialty = doctor.value.specialty?.id;
            state.linea2 = doctor.value.linea2;
            state.firma = doctor.value.firma;
            state.templates = doctor.value.templates?.map((t) => t.id);
            state.status = doctor.value.status;

            specialtySelected.value = doctor.value.specialty;
            specialtyAutocomplete.value.setText(doctor.value.specialty?.name || '');
        };

        const resetWindow = () => {
            state.id = '';
            state.consecutive = '';
            state.name = '';
            state.surnames = '';
            state.email = '';
            state.sex = '';
            state.specialty = '';
            state.linea2 = '';
            state.firma = '';
            state.templates = [];
            state.status = 1;

            specialtySelected.value = null;
            specialtyAutocomplete.value.setText('');
            doctor.value = null;
        };

        /** ***** Lifecycle Hooks ***** */
        onMounted(async () => {
            if (props.item) {
                await Promise.all([getDoctor(props.item.id), getExamenTemplates()]);
                if (doctor.value) {
                    setState();
                }
            } else {
                await getExamenTemplates();
            }
            isReady.value = true;
        });

        /** ***** Validator ***** */
        const rules = computed(() => ({
            name: { required },
            surnames: { required },
            email: { required, email },
            sex: { required },
            specialty: { required },
            // linea2: { required },
            status: { required },
        }));
        const $v = useVuelidate(rules, state);

        const handleAction = async (action) => {
            await $v.value.$validate();
            if (!$v.value.$error) {
                // No errors
                let text;
                switch (action) {
                    case 'new':
                    case 'new_view':
                        await createDoctor({
                            name: state.name,
                            surnames: state.surnames,
                            email: state.email,
                            sex: state.sex,
                            specialty: state.specialty,
                            linea2: state.linea2,
                            templates: state.templates,
                            status: state.status,
                        });
                        if (action === 'new_view') {
                            store.dispatch('ContentManager/openWindow', {
                                id: `DoctorForm_${doctor.value.id}`,
                                component: 'DoctorForm',
                                name: 'Especialista',
                                params: {
                                    title: 'Especialista',
                                    headerTitle: 'Editar Plantilla',
                                    item: { ...doctor.value },
                                },
                            });
                        }
                        resetWindow();
                        text = 'Especialista ingresado';
                        break;
                    case 'save':
                        await updateDoctor(state.id, {
                            name: state.name,
                            surnames: state.surnames,
                            email: state.email,
                            sex: state.sex,
                            specialty: state.specialty,
                            linea2: state.linea2,
                            templates: state.templates,
                            status: state.status,
                        });
                        text = 'Especialista actualizado';
                        break;
                    default:
                        break;
                }
                snackbar.add({
                    type: 'success',
                    text,
                });
                setState();
            } else {
                console.log($v.value.$errors);
            }
        };

        return {
            isReady,
            v: $v,
            state,
            /** Autocompleters */
            especialidades,
            getEspecialidades,
            specialtyAutocomplete,
            specialtySelected,
            onSelectSpecialty,
            /** Actions */
            handleAction,
            templates,
        };
    },
};
</script>

<style></style>
